import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { EventFilterType } from '../../../../../shared/models/enums/enums';
import { openCloseElementFlex } from '../../../../../shared/service/utils/export-functions';
import { UserDetailsService } from '../../../../../shared/service/user-details-service.service';
import { EventService, IEventDiscount } from '../../../../../shared/service/backend/event.service';
import { LocationService } from '../../../../../shared/service/backend/location.service';
import { City } from '../../../../../shared/models/City';
import { Category } from '../../../../../shared/models/Category';
import { CategoryService } from '../../../../../shared/service/backend/category.service';
import { Page } from '../../../../../shared/models/Page';
import { BoxOfficeEventDto } from '../../../../../shared/models/dto/BoxOfficeEventDto';
import { MainLoaderService } from '../../../../../shared/service/rx/main-loader.service';
import { HallService } from '../../../../../shared/service/backend/hall.service';
import { PlatformHall } from '../../../../../shared/models/PlatformHall';
import { BookingService } from '../../../../../shared/service/backend/booking.service';
import { MainSettingConfig, MainSettingsService } from '../../../../../shared/service/rx/main-settings.service';
import { UserWithBoxOfficeDto } from '../../../../../shared/models/dto/UserWithBoxOfficeDto';

class EventDate {
  date: string;
  events: BoxOfficeEventDto[] = [];
}

@Component({
  selector: 'app-event-container',
  templateUrl: './event-container.component.html',
  styleUrls: ['./event-container.component.css'],
})
export class EventContainerComponent implements OnInit {
  filterEventFilterType: EventFilterType = 'ALL';
  filterCity: number;
  filterPlatformHall: number;
  filterCategory: number;
  filterMonth: number;
  filterDateFrom: string = undefined;
  filterDateTo: string = undefined;
  count: number = 9;
  page: number = 0;

  showMoreEvents: boolean = true;

  eventDataPopUp: BoxOfficeEventDto;
  eventDiscounts: (IEventDiscount & { exludedPrices: number[] })[] = [];
  isSameEventDiscountExcludes: boolean = false;

  user: UserWithBoxOfficeDto = new UserWithBoxOfficeDto();

  allCities: City[] = [];
  // countries: Country[] = [];
  categories: Category[] = [];
  platformHalls: PlatformHall[] = [];

  events: Page<BoxOfficeEventDto>;
  eventsByDate: EventDate[] = [];

  @ViewChild('mainForm') mainForm: ElementRef;
  @ViewChild('researcher') researcher: ElementRef;
  // @ViewChild('mainForm') mainForm: ElementRef;
  lang: string;

  configSetting: MainSettingConfig = new MainSettingConfig();

  sort: 'ASC' | 'DESC' = 'ASC';

  byDate: boolean = true;

  constructor(
    private _userDetailService: UserDetailsService,
    private _eventService: EventService,
    private _locationService: LocationService,
    private _categoryService: CategoryService,
    private _mainLoader: MainLoaderService,
    private _hallService: HallService,
    private _translateService: TranslateService,
    private _bookingService: BookingService,
    private _activatedRoute: ActivatedRoute,
    private _mainSettingsService: MainSettingsService,
    private _router: Router,
  ) {
    this.user = this._userDetailService.getUser();
    this._userDetailService.user$.subscribe(
      next => {
        this.user = next;
      },
      error => {
        console.error(error);
      },
    );
    this.lang = this._translateService.currentLang;
    this._translateService.onLangChange.subscribe(
      next => {
        this.lang = next.lang;
      },
      error => {
        console.error(error);
      },
    );
    this._categoryService.findAll().subscribe(
      next => {
        this.categories = next;
      },
      error => {
        console.error(error);
      },
    );
    this._locationService.findAllCity().subscribe(
      next => {
        this.allCities = next;
      },
      error => {
        console.error(error);
      },
    );
    this.configSetting = this._mainSettingsService.getConfig();
    // this.configSetting.date == 'WITH' ? (this.byDate = true) : (this.byDate = false);
    this._mainSettingsService.settingConfig$.subscribe(
      next => {
        this.configSetting = next;
        // next.date == 'WITH' ? (this.byDate = true) : (this.byDate = false);
      },
      error => {
        console.error(error);
      },
    );
    this.loadRelevantMonths();
    this._activatedRoute.queryParams.subscribe(
      next => {
        if (next.cityId) {
          this.filterCity = +next.cityId;
          this.filterPlatformHall = +next.platformHallId;
          this._hallService.findAllPlatformHallsByCity(this.filterCity).subscribe(
            next => {
              this.platformHalls = next;
            },
            error => {
              console.error(error);
            },
          );
        }
        this.loadEventWithFilter();
      },
      error => {
        console.error(error);
      },
    );
  }

  groupEventByDate(arr, arrResult: EventDate[]) {
    arrResult = [];
    let tempArr: EventDate = new EventDate();
    arr.content.forEach((value, index) => {
      if (index == 0) {
        tempArr.date = value.beginDate;
        tempArr.events.push(value);
        if (arr.content.length == 1) {
          arrResult.push(tempArr);
        }
      } else {
        if (new Date(value.beginDate).toLocaleDateString() == new Date(tempArr.date).toLocaleDateString()) {
          tempArr.events.push(value);
          if (index == arr.content.length - 1) {
            arrResult.push(tempArr);
          }
        } else {
          arrResult.push(tempArr);
          tempArr = new EventDate();
          tempArr.date = value.beginDate;
          tempArr.events.push(value);
          if (index == arr.content.length - 1) {
            arrResult.push(tempArr);
          }
        }
      }
    });
    return arrResult;
  }

  loadRelevantMonths(): number[] {
    let startDate = new Date();
    let months: number[] = [];
    for (let i = startDate.getMonth(); i < 12; i++) {
      months.push(i);
    }
    return months;
  }

  changeFilterCity(val) {
    this.filterPlatformHall = undefined;
    this.loadEventWithFilter();
    if (!!val) {
      this._hallService.findAllPlatformHallsByCity(val).subscribe(
        next => {
          this.platformHalls = next;
        },
        error => {
          console.error(error);
        },
      );
    }
  }

  trackById(index, obj) {
    return obj.id;
  }

  loadEventWithFilter() {
    this.page = 0;
    this._mainLoader.startLoader();
    this._bookingService
      .filterEventsBoxOfficeStatisticByDateV2Short(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        this.filterMonth,
        this.filterDateFrom,
        this.filterDateTo,
        this.sort,
        this.byDate,
      )
      .subscribe(
        next => {
          this.events = next;
          if (next.content.length < this.count || next.last) {
            this.showMoreEvents = false;
          } else {
            this.showMoreEvents = true;
          }
          this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
          this.researcher.nativeElement.value = '';
          this._mainLoader.endLoader();
        },
        error => {
          this._mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  loadEventWithResearcher(value) {
    if (value.length > 0) {
      this.page = 0;
      this._mainLoader.startLoader();
      this._bookingService
        .filterEventsBoxOfficeStatisticResearcherbyDate(
          value,
          (this.lang == 'ua' ? 'uk' : this.lang).toLocaleUpperCase(),
          this.sort,
          this.byDate,
        )
        .subscribe(
          next => {
            this.events = next;
            if (next.content.length < this.count || next.last) {
              this.showMoreEvents = false;
            } else {
              this.showMoreEvents = true;
            }
            this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
            this.filterCity = undefined;
            this.filterPlatformHall = undefined;
            this.filterCategory = undefined;
            this.filterMonth = undefined;
            this.filterDateFrom = undefined;
            this.filterDateTo = undefined;
            this._mainLoader.endLoader();
          },
          error => {
            this._mainLoader.endLoader();
            console.error(error);
          },
        );
    } else {
      this.resetFilter();
    }
  }

  loadEventWithFilterEventType() {
    this.page = 0;
    this._mainLoader.startLoader();
    this.filterMonth = undefined;
    this.filterDateFrom = undefined;
    this.filterDateTo = undefined;
    this._bookingService
      .filterEventsBoxOfficeStatisticByDateV2Short(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        undefined,
        undefined,
        undefined,
        this.sort,
        this.byDate,
      )
      .subscribe(
        next => {
          this.events = next;
          if (next.content.length < this.count || next.last) {
            this.showMoreEvents = false;
          } else {
            this.showMoreEvents = true;
          }
          this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
          this.researcher.nativeElement.value = '';
          this._mainLoader.endLoader();
        },
        error => {
          this._mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  loadEventWithFilterMonth() {
    this.page = 0;
    this._mainLoader.startLoader();
    this.filterEventFilterType = 'ALL';
    this.filterDateFrom = null;
    this.filterDateTo = null;
    this._bookingService
      .filterEventsBoxOfficeStatisticByDateV2Short(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        this.filterMonth,
        undefined,
        undefined,
        this.sort,
        this.byDate,
      )
      .subscribe(
        next => {
          this.events = next;
          if (next.content.length < this.count || next.last) {
            this.showMoreEvents = false;
          } else {
            this.showMoreEvents = true;
          }
          this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
          this.researcher.nativeElement.value = '';
          this._mainLoader.endLoader();
        },
        error => {
          this._mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  loadEventWithFilterDate() {
    this.page = 0;
    this._mainLoader.startLoader();
    this.filterEventFilterType = 'ALL';
    this.filterMonth = undefined;
    this._bookingService
      .filterEventsBoxOfficeStatisticByDateV2Short(
        this.count,
        this.page,
        this.filterEventFilterType,
        this.filterCity,
        this.filterPlatformHall,
        this.filterCategory,
        undefined,
        this.filterDateFrom,
        this.filterDateTo,
        this.sort,
        this.byDate,
      )
      .subscribe(
        next => {
          this.events = next;
          if (next.content.length < this.count || next.last) {
            this.showMoreEvents = false;
          } else {
            this.showMoreEvents = true;
          }
          this.eventsByDate = this.groupEventByDate(next, this.eventsByDate);
          this.researcher.nativeElement.value = '';
          this._mainLoader.endLoader();
        },
        error => {
          this._mainLoader.endLoader();
          console.error(error);
        },
      );
  }

  setDefaultDataToInput(type: boolean) {
    if (!(<HTMLInputElement>event.target).value) return;
    let date = new Date();
    if (type) {
      if (!this.filterDateFrom || this.filterDateFrom == '') {
        this.filterDateFrom = date.toISOString().slice(0, 10);
      }
    } else {
      if (!this.filterDateTo || this.filterDateTo == '') {
        this.filterDateTo =
          +date.toISOString().slice(0, 10).slice(0, 4) +
          1 +
          '-' +
          date.toISOString().slice(0, 10).slice(5, 8) +
          date.toISOString().slice(0, 10).slice(8, 10);
      }
    }
  }

  moreFilter() {
    this._mainLoader.startLoader();
    this.page++;
    if (this.researcher.nativeElement.value.length == 0) {
      this._bookingService
        .filterEventsBoxOfficeStatisticByDateV2Short(
          this.count,
          this.page,
          this.filterEventFilterType,
          this.filterCity,
          this.filterPlatformHall,
          this.filterCategory,
          this.filterMonth,
          this.filterDateFrom,
          this.filterDateTo,
          this.sort,
          this.byDate,
        )
        .subscribe(
          next => {
            if (next.content.length < this.count || next.last) {
              this.showMoreEvents = false;
            } else {
              this.showMoreEvents = true;
            }
            next.content.forEach(value => {
              this.events.content.push(value);
            });
            this.events.last = next.last;
            this.events.totalElements = next.totalElements;
            this.events.totalPages = next.totalPages;
            this.events.size = next.size;
            this.events.number = next.number;
            this.events.sort = next.sort;
            this.events.first = next.first;
            this.events.numberOfElements = next.numberOfElements;
            this.eventsByDate = this.groupEventByDate(this.events, this.eventsByDate);
            this._mainLoader.endLoader();
          },
          error => {
            this._mainLoader.endLoader();
            console.error(error);
          },
        );
    } else {
      this.loadEventWithResearcher(this.researcher.nativeElement.value);
    }
  }

  resetFilter() {
    this.filterEventFilterType = 'ALL';
    this.filterCity = undefined;
    this.filterPlatformHall = undefined;
    this.filterCategory = undefined;
    this.filterMonth = undefined;
    this.filterDateFrom = null;
    this.filterDateTo = null;
    this.researcher.nativeElement.value = '';
    this.loadEventWithFilter();
  }

  openCloseBlock(el) {
    openCloseElementFlex(el);
  }

  ngOnInit() {}

  openPopUp(eventId) {
    if (this.events.content.find(el => el.id === eventId).isDiscountAvailable) {
      this._eventService.getEventDiscounts(eventId).subscribe(
        res => {
          this.isSameEventDiscountExcludes = res.every(el => el.excludedColorPrices === res[0].excludedColorPrices);
          this.eventDiscounts = res.map(el => {
            return {
              ...el,
              exludedPrices: el.excludedColorPrices
                .split(',')
                .map(id =>
                  el.colorPriceList.find(colorPrice => colorPrice.id === +id)
                    ? el.colorPriceList.find(colorPrice => colorPrice.id === +id).price
                    : null,
                )
                .filter(ele => ele),
            };
          });
        },
        // ),
      );
    }

    this._bookingService.getOneEvent(eventId).subscribe(
      next => {
        this.eventDataPopUp = next;
        openCloseElementFlex(document.getElementById('popUp'));
      },
      error => {
        console.error(error);
      },
    );
  }

  closePopUp() {
    this.eventDataPopUp = new BoxOfficeEventDto();
    openCloseElementFlex(document.getElementById('popUp'));
  }

  navigationToHome(id) {
    this._router.navigate(['/reserve'], { queryParams: { eventId: id } });
  }
}
