<div class="wrapper">
  <!-- <div class="tabs">
    <div [ngClass]="{ current: isPayedTab }">Оплачено</div>
    <div [ngClass]="{ current: !isPayedTab }">Не оплачено</div>
  </div> -->
  <ng-container *ngIf="_vendors.length; else noData">
    <nz-table
      #nestedTable
      [nzData]="_vendors"
      [nzPageSize]="pagination.paginationSize"
      [nzShowPagination]="true"
      [nzFrontPagination]="false"
      [nzPageIndex]="pagination.currentPage"
      [nzTotal]="pagination.totalElements"
      (nzPageIndexChange)="changePage($event)"
      [nzScroll]="{ x: '1100px' }"
      nzSize="small"
    >
      <thead>
        <tr>
          <th></th>
          <th>Назва</th>
          <th>Телефон</th>
          <th>Кількість замовлень</th>
          <th>Кількість квитків</th>
          <th>Сума</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-template ngFor let-vendor [ngForOf]="nestedTable.data" let-vendorIndex="index">
          <tr>
            <td (click)="expandVendor(vendor)" [(nzExpand)]="vendor.expand"></td>

            <td>
              <div class="editable-cell" [hidden]="editId === vendor.id">
                {{ vendor.fullName }}
              </div>
              <input [formControl]="vendorNameControl" [hidden]="editId !== vendor.id" [value]="vendor.fullName" />
            </td>
            <td>
              <div class="editable-cell" [hidden]="editId === vendor.id">
                {{ vendor.phone }}
              </div>
              <input
                [formControl]="vendorPhoneControl"
                [hidden]="editId !== vendor.id"
                [value]="vendor.phone"
                [textMask]="{
                  mask: PHONE_PATTERN,
                  guide: false,
                  modelClean: true
                }"
              />
            </td>
            <td>{{ vendor?.binsCount }}</td>
            <td>{{ vendor?.ticketsCount }}</td>
            <td>{{ vendor?.binsSum }}</td>
            <td>
              <a *ngIf="editId !== vendor.id; else saveItem" (click)="onVendorEdit(vendor)">
                <i nz-icon nzType="edit" nzTheme="outline"></i>
              </a>
              <ng-template #saveItem>
                <a (click)="onVendorSave(vendor.id)">
                  <i nz-icon nzType="check" nzTheme="outline"></i>
                </a>
              </ng-template>
            </td>
          </tr>

          <tr [nzExpand]="vendor.expand">
            <div class="bin-status-filter">
              <div (click)="vendor.binStatus = 'ACTIVE'; expandVendor(vendor)" [ngClass]="{ active: vendor.binStatus !== 'ARCHIVE' }">
                Активні
              </div>
              <div (click)="vendor.binStatus = 'ARCHIVE'; expandVendor(vendor)" [ngClass]="{ active: vendor.binStatus === 'ARCHIVE' }">
                Архівні
              </div>
            </div>
            <nz-table
              #innerTable
              [nzData]="getChildTable(vendorIndex)"
              nzSize="small"
              [nzPageSize]="10"
              [nzShowPagination]="true"
              [nzFrontPagination]="false"
              [nzPageIndex]="vendor.currentPage"
              [nzTotal]="vendor.totalElements"
              (nzPageIndexChange)="nzPageIndexChange($event, vendor.id, vendor.binStatus)"
              [nzScroll]="{ x: '1100px', y: '240px' }"
            >
              <thead>
                <tr [ngStyle]="{ 'font-size': '11px' }">
                  <th nzLeft rowspan="2">№ Замовлення</th>
                  <th rowspan="2">Дата Замовлення</th>
                  <th rowspan="2">Дата повернення</th>
                  <th rowspan="2">Подія</th>
                  <th rowspan="2">Дата події</th>
                  <th rowspan="2">Час події</th>
                  <th rowspan="2">Статус</th>
                  <th rowspan="2">Спосіб оплати</th>
                  <th colspan="2">Видано</th>
                  <th colspan="2">Повернуто</th>
                  <th colspan="2">Оплачено</th>
                  <th nzRight rowspan="2"></th>
                </tr>
                <tr [ngStyle]="{ 'font-size': '11px' }">
                  <th>К-сть</th>
                  <th>Сума</th>
                  <th>К-сть</th>
                  <th>Сума</th>
                  <th>К-сть</th>
                  <th>Сума</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let bin of innerTable.data; index as orderIndex">
                  <td nzLeft>{{ bin.order }}</td>
                  <td>{{ bin?.created | date : 'dd-MM-yyyy HH:mm' }}</td>
                  <td>----</td>
                  <td>{{ bin?.event?.name?.valueUA }}</td>
                  <td>
                    {{ bin?.event?.beginDate | date : 'dd-MM-yyyy HH:mm' }}
                  </td>
                  <td>
                    {{ getEventTime(bin?.event?.beginDate, bin?.event?.beginEndDate) | date : 'hh:mm' : 'UTC' }}
                  </td>
                  <td>{{ binStatusEnum[bin?.binStatus] }}</td>
                  <td>
                    {{ PAYMENT_TYPES_MAP[bin.typeOfPayment] }}
                  </td>
                  <td>{{ bin?.issuedTicketCount }}</td>
                  <td>{{ bin?.issuedTicketSum }}</td>
                  <td>{{ bin?.revertedTicketCount }}</td>
                  <td>{{ bin?.revertedTicketSum }}</td>
                  <td>{{ bin?.binStatus === 'BOUGHT' ? bin?.ticketCount || 0 : '-' }}</td>
                  <td>{{ bin?.binStatus === 'BOUGHT' ? bin?.ticketSum || 0 : '-' }}</td>
                  <td nzRight nzAlign="center">
                    <a class="operation" nz-dropdown nzTrigger="click" [nzDropdownMenu]="actions">
                      <i nz-icon nzType="bars" nzTheme="outline"></i>
                    </a>
                    <nz-dropdown-menu #actions="nzDropdownMenu">
                      <ul nz-menu>
                        <li nz-menu-item (click)="showActionsModal(vendorIndex, bin?.order, true)">
                          <i nz-icon nzType="eye" nzTheme="outline"></i>
                          Переглянути замовлення
                        </li>
                        <li nz-menu-item [class.disabled-item]="bin?.binStatus !== 'IN_PROGRESS_LOCKING_BY_VENDOR'">
                          <a
                            routerLink="/order/{{ bin.event.id }}"
                            [queryParams]="{
                              bin: bin.order,
                              vendor: vendor.id
                            }"
                          >
                            <i nz-icon nzType="edit" nzTheme="outline"></i>
                            Редагувати замовлення
                          </a>
                        </li>
                        <li
                          nz-menu-item
                          [class.disabled-item]="bin?.binStatus !== 'IN_PROGRESS_LOCKING_BY_VENDOR'"
                          (click)="removeOrder(bin?.order)"
                        >
                          <i nz-icon nzType="delete" nzTheme="outline"></i>
                          Видалити замовлення
                        </li>
                        <li
                          nz-menu-item
                          (click)="showActionsModal(vendorIndex, bin?.order)"
                          [class.disabled-item]="bin?.binStatus !== 'IN_PROGRESS_LOCKING_BY_VENDOR'"
                        >
                          <i nz-icon nzType="file-text" nzTheme="outline"></i>
                          Виписати рахунок
                        </li>
                        <li
                          nz-menu-item
                          [class.disabled-item]="bin?.binStatus === 'BOUGHT'"
                          (click)="showActionsModal(vendorIndex, bin?.order)"
                        >
                          <i nz-icon nzType="printer" nzTheme="outline"></i>
                          Друк квитків (замовлення)
                        </li>

                        <li
                          nz-menu-item
                          [class.disabled-item]="bin?.binStatus === 'LOCKED_BY_VENDOR' || bin?.binStatus === 'BOUGHT'"
                          (click)="printBuyOrder(vendor.id, bin.order, bin.event.id, false, true)"
                        >
                          <i nz-icon nzType="printer" nzTheme="outline"></i>
                          Друк накладної на видачу
                        </li>
                        <li
                          nz-menu-item
                          [class.disabled-item]="bin?.binStatus === 'LOCKED_BY_VENDOR' || bin?.binStatus === 'BOUGHT'"
                          (click)="checkReturnOption(vendorIndex, orderIndex, false, 'return', bin?.binStatus)"
                        >
                          <i nz-icon nzType="swap" nzTheme="outline"></i>
                          Повернення
                        </li>
                        <li
                          nz-menu-item
                          [class.disabled-item]="bin?.binStatus !== 'CANCELLED_BY_BOXOFFICE'"
                          (click)="printBuyOrder(vendor.id, bin.order, bin.event.id, true)"
                        >
                          <i nz-icon nzType="printer" nzTheme="outline"></i>
                          Друк накладної на повернення
                        </li>

                        <li
                          nz-submenu
                          [class.disabled-item]="bin?.binStatus === 'LOCKED_BY_VENDOR' || bin?.binStatus === 'BOUGHT'"
                          nzTitle="Підтвердження оплати"
                        >
                          <ul>
                            <li
                              nz-menu-item
                              *ngFor="let paymentType of PAYMENT_TYPES"
                              (click)="buyBin(vendor?.id, bin?.order, paymentType.type)"
                            >
                              {{ paymentType.label }}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nz-dropdown-menu>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </tr>
        </ng-template>
      </tbody>
    </nz-table>
  </ng-container>

  <ng-template #noData>
    <nz-empty></nz-empty>
  </ng-template>
</div>
